import React from "react"
import CTAInlink from "../components/CTAInLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function () {
  return (
    <Layout>
      <SEO title="Page Not Found"/>
      
      <section className="lg:mt-16 lg:px-24">
        <h1 className="section-head text-center">Page Not Found</h1>
        <p className="text-center mt-4 lg:mt-8">
          Sorry, the page you're looking for doesn't exist.
        </p>
        <div className="text-center mt-8 lg:mt-16">
          <CTAInlink
            text="Take Me Home"
            linkto="/"
            className="bg-red-800 hover:bg-red-900"
          />
        </div>
      </section>
    </Layout>
  )
}
